import { Component, Input, OnChanges, OnInit } from "@angular/core";
import { ObjectService } from "@app/core/ngrx/entity-services/object.service";
import { QObject } from "@app/models";
import { Description, ShowingObject } from "@app/showings/models";
import {
  BehaviorSubject,
  debounceTime,
  filter,
  first,
  map,
  Observable,
} from "rxjs";

@Component({
  selector: "app-object-descriptions-widget",
  templateUrl: "./object-descriptions-widget.component.html",
  styleUrls: [
    "./object-descriptions-widget.component.scss",
    "../../widget/widget.common.scss",
  ],
})
export class ObjectDescriptionsWidgetComponent implements OnInit, OnChanges {
  @Input() eaOid: string;
  descriptions$: Observable<Description[]>;
  sellingTextHeader$: Observable<string>;
  sellingTextShort$: Observable<string>;
  sellingTextFull$: Observable<string>;
  objectPresentation$: Observable<string>;
  showingObject$: BehaviorSubject<QObject & ShowingObject> =
    new BehaviorSubject<QObject & ShowingObject>(null);

  constructor(private objectService: ObjectService) {}

  ngOnInit(): void {
    this.mapStateToProps();
  }

  ngOnChanges(changes) {
    if (changes.eaOid && this.eaOid) {
      this.objectService.entityMap$
        .pipe(
          map((entities) => entities[this.eaOid]),
          debounceTime(200),
          filter((entity) => !!entity),
          first()
        )
        .subscribe((obj) => {
          this.showingObject$.next(obj);
        });
    }
  }

  private mapStateToProps() {
    this.descriptions$ = this.showingObject$.pipe(
      filter((showing) => !!showing),
      map((showing) => showing.descriptions)
    );
    this.sellingTextHeader$ = this.descriptions$.pipe(
      map((descriptions) => {
        const desc = descriptions?.find(
          (desc) => desc.originalTag.toLowerCase() === "selling_text_header"
        );
        return !!desc ? desc?.description : "";
      })
    );
    this.sellingTextShort$ = this.descriptions$.pipe(
      map((descriptions) => {
        const desc = descriptions?.find(
          (desc) => desc.originalTag.toLowerCase() === "selling_text_short"
        );
        return !!desc ? desc?.description : "";
      })
    );
    this.sellingTextFull$ = this.descriptions$.pipe(
      map((descriptions) => {
        const desc = descriptions?.find(
          (desc) => desc.originalTag.toLowerCase() === "selling_text_full"
        );
        return !!desc ? desc?.description : "";
      })
    );

    this.objectPresentation$ = this.descriptions$.pipe(
      map((descriptions) => {
        const desc = descriptions?.find(
          (desc) => desc.originalTag.toLowerCase() === "object_presentation"
        );
        return !!desc ? desc?.description : "";
      })
    );
  }
}
