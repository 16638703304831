import {
  Component,
  Input,
  OnInit,
  TemplateRef,
  ViewEncapsulation,
} from "@angular/core";
import { MatBottomSheet } from "@angular/material/bottom-sheet";
import { AppState } from "@app/app.reducer";
import { isMobile } from "@app/shared/ngrx/shared.reducer";
import { select, Store } from "@ngrx/store";
import { Observable } from "rxjs";

@Component({
  selector: "app-actions-menu",
  template: `
    <div
      *ngIf="!(isMobile$ | async); else mobile"
      dropdown
      placement="bottom left"
      class="relative"
      [style.display]="size === 'x-small' ? 'flex' : null"
      [ngClass]="dropdownAlignment.toLowerCase()"
      (mouseenter)="showDropdown()"
      (mouseleave)="hideDropdown()"
    >
      <button
        id="button-alignment"
        type="button"
        dropdownToggle
        (click)="onActionsMenuClick($event)"
        [class]="
          'btn btn-default action-menu-dropdown-toggle dropdown-toggle ' +
          getClasses()
        "
        (mouseenter)="showDropdown()"
      >
        <app-icon [size]="size" [name]="icon"></app-icon>
      </button>
      <ul
        id="dropdown-alignment"
        class="dropdown-menu action-menu-dropdown"
        [ngClass]="
          dropdownAlignment === 'LEFT'
            ? 'dropdown-menu-left'
            : 'dropdown-menu-right'
        "
        [style.display]="dropdownVisible ? 'block' : 'none'"
        (mouseenter)="showDropdown()"
        (mouseleave)="hideDropdown()"
      >
        <ng-container *ngTemplateOutlet="content"></ng-container>
      </ul>
    </div>

    <ng-template #mobile>
      <div class="relative">
        <button
          (click)="onActionsMenuClick($event); openSheet(sheet)"
          id="button-alignment"
          [class]="'btn btn-default dropdown-toggle ' + getClasses()"
        >
          <app-icon [size]="size" [name]="icon"></app-icon>
        </button>
      </div>

      <ng-template #sheet>
        <mat-nav-list (click)="closeSheet()" class="action-menu-dropdown">
          <ul>
            <ng-container *ngTemplateOutlet="content"></ng-container>
          </ul>
        </mat-nav-list>
      </ng-template>
    </ng-template>

    <ng-template #content>
      <ng-content select="[buttons]"></ng-content>
    </ng-template>
  `,
  styleUrls: ["./actions-menu.component.scss"],
  encapsulation: ViewEncapsulation.None,
})
export class ActionsMenuComponent implements OnInit {
  @Input() icon: string = "ellipsis-h-alt";
  @Input() dropdownAlignment: "LEFT" | "RIGHT" = "RIGHT";
  @Input() highlight = false;
  @Input() size: "x-small" | "medium" = "medium";
  @Input() menuButtonClass: string = "";

  isMobile$: Observable<boolean>;
  dropdownVisible = false;

  constructor(
    private _bottomSheet: MatBottomSheet,
    private store: Store<AppState>
  ) {}

  ngOnInit() {
    this.mapStateToProps();
  }

  onActionsMenuClick(e: Event): void {
    e.stopPropagation();
    this.dropdownVisible = !this.dropdownVisible;
  }

  getClasses() {
    let classes = "";
    if (!!this.highlight) {
      classes = `${classes} btn-circle btn-secondary`;
    }
    if (this.size === "x-small") {
      classes = `${classes} action-menu-x-small-button`;
    }
    if (this.menuButtonClass) {
      classes = `${classes} ${this.menuButtonClass}`;
    }

    return classes;
  }

  private mapStateToProps() {
    this.isMobile$ = this.store.pipe(select(isMobile));
  }

  openSheet(sheet: TemplateRef<any>) {
    this._bottomSheet.open(sheet, { panelClass: "sheet-container" });
  }

  closeSheet() {
    this._bottomSheet.dismiss();
  }

  showDropdown() {
    this.dropdownVisible = true;
  }

  hideDropdown() {
    setTimeout(() => {
      this.dropdownVisible = false;
    }, 200);
  }
}

@Component({
  selector: "app-actions-menu-sheet",
  template:
    '<mat-nav-list><ng-content select="[buttons]"></ng-content></mat-nav-list>',
})
export class ActionsMenuSheetComponent {}
